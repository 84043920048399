.c-map {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    background: $ui-color;

    @media #{$large-and-up} {
        padding-bottom: 52%;
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #inner-map {
        width: inherit;
        height: inherit;
    }
}
