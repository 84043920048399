.c-status {
    $c: '.c-status';

    $icon-size: 70px;
    $border-height: 4px;
    $cta-width: 70px;
    $item-width: 110px;

    position: relative;
    color: $black-color;
    min-height: 230px;
    margin: $spacer (-$gutter-width / 2);

    @media #{$large-and-up} {
        margin: $spacer (-$card-padding);
    }

    &:before {
        height: $border-height;
        content: '';
        display: block;
        background-color: lighten($primary-color, 30%);
        position: absolute;
        left: 0;
        width: 100%;
        top: $icon-size + $border-height + 16px;
    }

    &__wrapper {
        position: relative;
        width: 100%;
        min-height: inherit;
        padding-right: $cta-width / 1.5;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 1;
        white-space: nowrap;

        @media #{$large-and-up} {
            display: flex;
            justify-content: space-between;
        }
    }

    &__item {
        position: relative;
        width: $item-width;
        text-align: center;
        white-space: normal;
        display: inline-block;
        vertical-align: top;

        @media #{$large-and-up} {
            width: 100%;
        }

        &.is-clickable {
            cursor: pointer;

        }

        &.is-current {
            color: $primary-color;
        }

        &:before {
            height: $border-height;
            content: '';
            display: block;
            background-color: $primary-color;
            position: absolute;
            left: -3px;
            right: -3px;
            top: $icon-size + $border-height + 16px;
        }

        &:not(.is-active) {
            opacity: .3;

            &:before {
                visibility: hidden;
            }

            #{$c}__dot {
                visibility: hidden;
            }
        }
    }

    &__icon {
        width: $icon-size;
        height: $icon-size;
        margin-right: auto;
        margin-left: auto;

        i {
            font-size: 3.6rem;
        }

        img {
            width: inherit;
            height: inherit;
        }
    }

    &__dot {
        width: 20px;
        height: 20px;
        background-color: $primary-color;
        border-radius: 100%;
        display: inline-block;
        margin: .8rem 0;
        transition: transform .3s ease;
    }

    &__text {
        width: 50%;
        height: 70px;
        margin: 0 auto;
        line-height: 1.3;
    }

    &__cta {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        bottom: 0;
        width: $cta-width;
        background: linear-gradient(90deg, rgba($white-color, 0) 0%, rgba($white-color, 1) 40%);

        > * {
            position: absolute;
            top: $icon-size + $border-height + 16px;
            right: $gutter-width / 2;
            margin-top: -18px;

            @media #{$medium-and-up} {
                right: $card-padding;
            }
        }
    }

    .c-loader {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.no-edit-button {
        #{$c}__wrapper {
            padding-right: 0;
        }

        #{$c}__item::before {
            left: 0;
            right: 0;
        }
    }
}

.c-statusWizard {
    margin: $spacer (-$gutter-width / 2);

    &.swiper-container {
        // overflow-x: hidden;
        // overflow-y: initial;
    }

    .section {
        padding-left: $gutter-width / 2;
        padding-right: $gutter-width / 2;
    }


    .collapsible li.active > .collapsible-header {
        background-color: lighten($ui-color, 10%);

        i.material-icons {
            color: $primary-color;
        }
    }

    @media #{$large-and-up} {
        margin: $spacer (-$card-padding);

        .section {
            padding-left: $card-padding;
            padding-right: $card-padding;
        }
    }
}
