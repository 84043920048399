.c-logbook {
    position: relative;

    &__input {
        margin-bottom: $spacer * 2;
    }

    &__wrapper {
        position: relative;
        background: $body-bg;
        padding: 0 $spacer;

        @media #{$medium-and-up} {
            padding: $card-padding $spacer * 2;
        }
    }

    &__show {
        text-align: center;
        margin-top: $spacer;

        .btn-flat {
            color: $primary-color;
        }
    }

    .c-log {
        $cta-width: 60px;

        padding: $spacer / 2 0;

        @media #{$medium-and-up} {
            padding: $spacer 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $ui-color;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }

        &__title {
            position: relative;
            color: $black-color;
            font-weight: 600;
            margin-bottom: $spacer / 3;
            padding-right: $cta-width;
        }

        &__cta {
            position: absolute;
            top: 0;
            right: 0;
            width: $cta-width;
        }

        &__body {
            > * {
                margin: 0;
            }
        }

        ul {
            margin-bottom: 0;
        }

        &.is-note {
            background-color: #fffee3;
            padding-left: $spacer;
            padding-right: $spacer;
            margin-left: -$spacer;
            margin-right: -$spacer;
            margin-bottom: $spacer / 2;

            @media #{$medium-and-up} {
                margin-left: 0;
                margin-right: 0;
                padding-left: $spacer * 2;
                padding-right: $spacer * 2;
                margin-bottom: $spacer;
            }
        }
    }
}
