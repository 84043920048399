.c-section-navigator {
    text-align: center;
    position: relative;
    display: inline-block;
    text-align: center;

    &:not(:last-child) {
        margin-right: $spacer * 2;
    }

    &__icon {
        margin: 0 auto;
        z-index: 4;
        color: $primary-color;
        font-size: 6rem;
    }
}
