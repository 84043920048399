.c-productsPreview {
    text-align: center;
    margin: 1.5rem (-$gutter-width / 2);
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: flex-start;

    &__item {
        display: inline-block;
        padding: 0 $gutter-width / 2;
        font-size: .9rem;
        line-height: 1.3;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 100px;

        img {
            height: 77px;
        }
    }
}
