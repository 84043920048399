.c-titleGroup {
    color: $headings-color;
    margin-bottom: 1rem;

    > *:not(:last-child) {
        margin-bottom: .5rem;
    }

    &__pretitle {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: .85rem;

        span {
            text-transform: none;
        }
    }

    &__title {
        font-size: 2rem;
        line-height: 1;
    }

    &__subtitle {
        color: $body-text;
    }
}
