.c-sidemenu {
    .sidenav {
        padding-top: $navbar-height-mobile;
        z-index: $sidemenu-z-index;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .collapsible {
            > .active {
                border-left: 0;

                a > i.material-icons {
                    color: inherit;
                }
            }

            &-header {
                &:focus {
                    background-color: inherit !important;
                }
            }

            &-body {
                background-color: inherit;

                > ul:not(.collapsible) > li.active,
                .sidenav.sidenav-fixed .collapsible-body > ul:not(.collapsible) > li.active {
                    background-color: inherit;
                    a {
                        color: #fff;
                        > i.material-icons {
                            color: $primary-color;
                        }
                    }
                }
            }
        }

        @media #{$medium-and-up} {
            padding-top: $navbar-height;
        }

        li {
            a > i.material-icons,
            button > i.material-icons {
                color: inherit;
                margin: 0 !important;
                float: right;
            }

            a,
            button {
                padding-top: .65rem;
                padding-bottom: .65rem;
                height: auto;

                @media #{$medium-and-down} {
                    padding-left: 16px !important;
                    padding-right: 16px !important;
                }

                &:hover {
                    background-color: rgba(#000, .2);
                }
            }

            button {
                color: $sidenav-font-color;
                display: block;
                font-size: $sidenav-font-size;
                font-weight: 500;
                height: $sidenav-item-height;
                line-height: $sidenav-line-height;
                padding: 0 ($sidenav-padding * 2);
                width: 100%;
                text-align: inherit;
                background: transparent;
                border: 0;
                cursor: pointer;

                &:focus {
                    outline: -webkit-focus-ring-color auto 5px;
                }

                & > i,
                & > [class^="mdi-"], li > a > [class*="mdi-"],
                & > i.material-icons {
                    float: right;
                    height: $sidenav-item-height;
                    line-height: $sidenav-line-height;
                    width: $sidenav-item-height / 2;
                }
            }

            &.active {
                background-color: rgba(#000, .2);

                a > i.material-icons,
                button > i.material-icons {
                    color: $primary-color !important;
                }
            }
        }

        .collapsible-body li.active,
        &.fixed .collapsible-body li.active {
            border-left: 2px solid $sidenav-active-font-color;

            a,
            button {
                background-color: rgba(#000, .2);
            }
        }
    }

    @media #{$large-only} {
        .sidenav {
            width: $sidenav-width-tablet;
            padding-bottom: $navbar-height-mobile * 2;

            li {
                a,
                button {
                    padding: ($spacer * 1.5) 8px;
                    display: block;
                    text-align: center;
                    line-height: 18px;
                    font-size: 12px;

                    i.material-icons {
                        margin: 0 auto !important;
                        display: block;
                        float: none;
                        height: 28px;
                        line-height: 28px;
                    }
                }

                &.active {
                    ul li {
                        a,
                        button {
                            padding-right: 16px;

                            i.material-icons {
                                margin-left: auto !important;
                            }
                        }
                    }
                }
            }
        }

        + .c-page {
            margin-left: $sidenav-width-tablet;

            + .c-footer {
                margin-left: $sidenav-width-tablet;
            }
        }
    }

    @media #{$extra-large-and-up} {
        .sidenav {
            width: $sidenav-width-desktop;

            .collapsible-header {
                padding-left: $sidenav-item-padding;
                padding-right: $sidenav-item-padding;
            }

            .collapsible-body li a,
            &.fixed .collapsible-body li a {
                padding-left: 60px;
            }

            .collapsible-body li.active,
            &.fixed .collapsible-body li.active {
                border-left: 5px solid $sidenav-active-font-color;

                a,
                button {
                    padding-left: 55px;
                }
            }
        }

        + .c-page {
            margin-left: $sidenav-width-desktop;

            + .c-footer {
                margin-left: $sidenav-width-desktop;
            }
        }
    }
}
