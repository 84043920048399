.c-rating {
    $color-gold: #ffd700;
    margin-bottom: $spacer * 2;

    &.is-disabled {
        pointer-events: none;
    }

    &__title {
        font-size: 1.15rem;
        line-height: 110%;
        font-weight: 400;
        margin-top: 0;
        color: $headings-color;
        margin-bottom: $spacer * 1.5;
    }

    &__stars {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;

        label {
            display: inline-block;
            cursor: pointer;
            color: $ui-color;
            margin-right: $spacer / 2;

            &[for="max[5]"] {
                margin-right: 0;
            }

            > i {
                user-select: none;
                font-size: 2.4rem;
            }
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;

            &:checked ~ label,
            &:checked ~ label ~ label {
                color: $color-gold;
            }

            &:not(:checked) ~ label:hover,
            &:not(:checked) ~ label:hover ~ label {
                color: darken($color-gold, 5%);
            }
        }
    }
}
