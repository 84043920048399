/* Validation Sass Placeholders */
%valid-input-style {
  border-bottom: 1px solid $input-success-color;
  box-shadow: 0 1px 0 0 $input-success-color;
}
%invalid-input-style {
  border-bottom: $input-invalid-border;
  box-shadow: 0 1px 0 0 $input-error-color;
}
%valid-color {
  color: $input-success-color;
}
%invalid-color {
  color: $input-error-color;
}
%hidden-text {
  color: transparent;
  user-select: none;
  pointer-events: none;
}
%custom-success-message {
  content: attr(data-success);
  color: $input-success-color;
}
%custom-error-message {
  content: attr(data-error);
  color: $input-error-color;
}
%input-after-style {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: .2s opacity ease-out, .2s color ease-out;
}

.input-field {
    margin-bottom: 2rem;

    > label {
        &:not(.label-icon).active {
            transform: translateY(-14px) scale(0.9);
        }
    }

    .c-loader {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;

        .preloader-wrapper {
            width: inherit;
            height: inherit;
        }
    }

    &.has-icon-cta {
        position: relative;
    }

    &__iconCta {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        z-index: 1;
        background: transparent !important;
        margin: 0;
        padding: 0;
        border: 0;
        cursor: pointer;
        color: inherit;
    }

    &--checkbox,
    &--radio {
        > label {
            position: relative;
            color: inherit;
        }

        input {
            // Valid Input Style
            &.valid + span,
            &:focus.valid + span {
                @extend %valid-color;
            }

            // Custom Success Message
            &.valid ~ .helper-text:after,
            &:focus.valid ~ .helper-text:after {
                @extend %custom-success-message;
            }

            // Invalid Input Style
            &.invalid + span,
            &:focus.invalid + span {
                @extend %invalid-color;
            }

            // Custom Error message
            &.invalid ~ .helper-text:after,
            &:focus.invalid ~ .helper-text:after {
                @extend %custom-error-message;
            }
        }
    }

    &--checkbox {
        > label span {
            line-height: 21px !important;
        }
    }

    &--select {
        .select-wrapper + label {
            font-size: .9rem;
        }

        .select-dropdown {
            max-height: 328px;
        }

        .multiple-select-dropdown label {
            color: inherit;
        }

        &.is-mobile {
            label {
                transform: translateY(-14px) scale(0.9);
            }

            .caret {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;
                z-index: 0;
                fill: rgba(0, 0, 0, 0.87);
            }
        }

        select.browser-default {
            position: relative;
            cursor: pointer;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #c7ccdb;
            outline: none;
            height: 3rem;
            line-height: 3rem;
            width: 100%;
            font-size: 16px;
            margin: 0 0 8px 0;
            padding: 0;
            display: block;
        }

        &.valid {
            .select-dropdown {
                @extend %valid-input-style;
            }
        }

        &.invalid {
            .select-dropdown {
                @extend %invalid-input-style;
            }

            .helper-text:after {
                @extend %custom-error-message;
            }
        }

        &.is-loading {
            .caret {
                display: none;
            }
        }
    }

    &--switch {
        .switch > div {
            margin-bottom: .75rem;
        }

        .helper-text {
            margin-top: .75rem;
        }

        &.invalid {
            label {
                @extend %invalid-color;
            }

            .helper-text:after {
                @extend %custom-error-message;
            }
        }
    }

    &--file {
        > label {
            position: relative;
            margin-bottom: -.5rem;
            display: block;
        }

        .file-path {
            &.is-set {
                &::placeholder {
                    color: inherit;
                }
            }
        }

        &__reset {
            margin: 1rem 0;

            a {
                display: inline-block;
                color: $primary-color;
            }
        }
    }

    &--tags {
        &[disabled] {
            pointer-events: none;
        }
    }

    .postfix {
        position: absolute;
        width: 3rem;
        font-size: 2rem;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        top: 0.5rem;
        right: 0;
        text-align: right;
        ~ input,
        ~ textarea,
        ~ label,
        ~ .validate ~ label,
        ~ .helper-text,
        ~ .autocomplete-content {
            margin-left: 0;
            margin-right: 3rem;
            width: 92%;
            width: calc(100% - 3rem);
        }
    }
}

.is-read-only {
    .input-field {
        margin-bottom: 1rem;

        input,
        select,
        textarea {
            pointer-events: none;
            border-bottom: 0;
        }

        .caret,
        .postfix {
            display: none;
        }

        &--checkbox,
        &--radio,
        &--switch {
            pointer-events: none;
            height: 3rem;
        }
    }
}
