.c-productsList {
    position: relative;

    .c-product {
        display: flex;
        align-items: center;
        padding: $spacer 0;
        color: $black-color;

        &:not(:last-child) {
            border-bottom: 1px solid $lighten-color;
        }

        &__media {
            width: 60px;
            height: 60px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 60px;

            @media #{$medium-and-up} {
                width: 80px;
                height: 80px;
                flex-basis: 80px;
            }
        }

        &__body {
            padding: ($spacer / 2) $spacer;
            flex-grow: 1;
        }

        &__category {
            font-size: .9rem;
        }

        &__name {
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 1.2;
        }

        &__variants {
            margin-top: $spacer * 1.5;
            margin-bottom: 0;

            .select-wrapper {
                input.select-dropdown {
                    height: 2em;
                    line-height: 2em;
                }

                + label {
                    font-size: .8rem;
                }
            }
        }

        &__cta {
            position: relative;
            left: -$spacer;
            margin-left: auto;
            text-align: right;

            .input-field--checkbox {
                margin: 0;

                input[type="checkbox"] + span:not(.lever) {
                    padding-left: 0;
                }
            }
        }

        &__btn {
            $size: 24px;

            width: $size;
            height: $size;
            line-height: $size;

            i.material-icons {
                width: inherit;
                height: inherit;
                line-height: inherit;
            }
        }
    }
}

#modal-products {
    .c-productsList {
        margin-left: -$gutter-width / 2;
        margin-right: -$gutter-width / 2;

        .c-product {
            padding-left: $gutter-width / 2;
            padding-right: $gutter-width / 2;

            &__cta {
                margin-right: $gutter-width / 2;
            }
        }

        @media #{$medium-and-up} {
            margin-left: -$card-padding;
            margin-right: -$card-padding;

            .c-product {
                padding-left: $card-padding;
                padding-right: $card-padding;

                &__cta {
                    margin-right: $card-padding / 2;
                }
            }
        }
    }
}
