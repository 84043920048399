.c-cornerInfo {
    font-weight: 600;
    color: color('wecanstopit', 'dark-grey');
    margin-bottom: 2rem;

    .vm-icon {
        font-size: 2rem;
    }

    div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.is-danger {
        color: $danger-color;
    }

    &.is-warning {
        color: $error-color;
    }

    @media (min-width: $large-screen) {
        &.lead {
            font-size: 1.2rem;

            i.material-icons {
                font-size: 30px;
            }
        }
    }
}
