.c-accelerometer {
    position: relative;
    padding-bottom: $spacer;
    margin-bottom: $spacer * 2;

    &__wrap {
        width: 92%;
        margin: 0 auto;
        display: block;
        padding-bottom: 50%;
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__bg {
        background-image: url('images/acc-bg.svg');
        background-size: 95%;
        background-repeat: no-repeat;
        background-position: center top;
        width: 100%;
        height: 100%;
        display: block;
    }

    &__pointer {
        position: absolute;
        bottom: 2%;
        height: 60px;
        width: 40%;
        left: 50%;
        background-image: url('images/acc-pointer.svg');
        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(-180deg);
        transform-origin: left center;
    }

    &__min,
    &__max {
        position: absolute;
        z-index: 1;
        bottom: -2.2rem;

        .c-rating {
            i {
                font-size: 1.5rem;
            }

            &__stars label {
                margin-right: 0;
            }
        }
    }

    &__min {
        left: 20px;
    }

    &__max {
        right: 20px;
    }
}
