.c-blank-box {
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    height: 280px;
    background: $body-bg;
    margin-bottom: 1rem;
    padding: 1rem;

    > * {
        width: 100%;
        display: block;
    }

    &.has-light-bg {
        background: $lighten-color;
    }

    &.no-bg {
        background: transparent;
    }
}
