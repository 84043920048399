.container {
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2;
}

.container-fluid {
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2;
    width: 100%;
    max-width: none;
}

.sidenav-overlay {
    z-index: $sidemenu-z-index - 1;
}

.section {
    position: relative;

    &.is-overflow-in-card-panel {
        overflow: hidden;
        margin: -$gutter-width / 2;
        padding: $gutter-width $gutter-width / 2;

        @media #{$large-and-up} {
            margin: -$card-padding;
            padding: ($card-padding * 1.5) $card-padding;
        }

        .c-cornerInfo {
            margin-right: $gutter-width / 2;

            @media #{$large-and-up} {
                margin-right: $card-padding;
            }
        }
    }
}

.divider {
    margin: $spacer 0;

    @media #{$medium-and-up} {
        margin: $spacer * 2 0;

        &.small {
            margin: $spacer 0;
        }
    }
}

.card,
.card-panel  {
    .divider {
        margin-left: -$gutter-width / 2;
        margin-right: -$gutter-width / 2;

        @media #{$large-and-up} {
            margin-left: -$card-padding;
            margin-right: -$card-padding;
        }
    }
}

.card-panel {
    position: relative;
}

.card .card-content,
.card-panel  {
    @media #{$medium-and-down} {
        padding: $gutter-width / 2;
    }
}

.chip {
    border-radius: 3px;
    height: 26px;
    line-height: 26px;

    .close {
        line-height: inherit;
    }

    &--large {
        height: 32px;
        line-height: 32px;
        font-size: 1rem;
    }
}

.row {
    margin-left: -$gutter-width / 2;
    margin-right: -$gutter-width / 2;

    @media (min-width: $medium-screen) {
        &.double-gutter {
            margin-left: -$gutter-width;
            margin-right: -$gutter-width;

            *[class^="col"] {
                padding-left: $gutter-width;
                padding-right: $gutter-width;
            }
        }
    }
}

.modal {
    width: calc(100% - #{$gutter-width});

    .modal-content {
        padding-bottom: 0;
        -webkit-overflow-scrolling: touch;

        @media #{$medium-and-down} {
            padding-left: $gutter-width / 2;
            padding-right: $gutter-width / 2;
        }
    }

    .modal-sticky-bar {
        background-color: #fafafa;
        margin-left: -$card-padding;
        margin-right: -$card-padding;
        padding: $card-padding $card-padding 0;
        top: -$card-padding * 1.5;
        z-index: 2;
        position: sticky;
        box-shadow: 0 5px 10px #fafafa;

        @media #{$medium-and-down} {
            margin-left: -$gutter-width / 2;
            margin-right: -$gutter-width / 2;
            padding-left: $gutter-width / 2;
            padding-right: $gutter-width / 2;
        }
    }

    .modal-footer {
        padding-left: $gutter-width / 2;
        padding-right: $gutter-width / 2;
        height: auto;

        @media #{$medium-and-up} {
            padding-left: $card-padding / 2;
            padding-right: $card-padding / 2;
        }
    }

    &-sm {
        max-width: $small-screen;
    }

    &-md {
        max-width: $medium-screen;
    }
}

.preloader-wrapper.center {
    display: block;
    margin: $spacer auto;
}

.pagination {
    @media #{$medium-and-down} {
        .waves-effect {
            display: none;
        }

        .prev-link,
        .next-link,
        .active + .waves-effect,
        .active + .waves-effect + .waves-effect,
        .waves-effect:first-child,
        .waves-effect:last-child,
        .waves-effect:nth-last-child(2) {
            display: inline-block;
        }
    }
}

.btn-inline {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
}

.btn-min-width {
    min-width: 240px;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

table.responsive-table {
    tbody {
        -webkit-overflow-scrolling: touch;
    }

    @media #{$medium-and-down} {
        overflow: hidden;

        thead {
            border-right: 0;

            tr {
                width: 80px;
            }
        }

        tbody {
            border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}

.collapsible {
    $c: '.collapsible';

    &-body {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &.is-locked {
        .collapsible-header {
            pointer-events: none;
        }
    }

    &.flat {
        margin: 0;
        padding: 0 !important;
        border: 0;
        box-shadow: none;

        #{$c}-header,
        #{$c}-body {
            border-bottom: 0;

            .input-field {
                margin-top: 0;
            }
        }

        #{$c}-header {
            padding: 0 !important;
            background-color: transparent;
        }

        #{$c}-body {
            padding: 0;
        }

    }
}

.autocomplete-content.dropdown-content {
    max-height: 300px;
}

.btn-small {
    padding: 0 10px;
}
