@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7ref07') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7ref07') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7ref07##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.vm-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.quality {
  &:before {
    content: $quality; 
  }
}
.concept-engineering {
  &:before {
    content: $concept-engineering; 
  }
}
.order-management {
  &:before {
    content: $order-management; 
  }
}
.calculations {
  &:before {
    content: $calculations; 
  }
}
.community {
  &:before {
    content: $community; 
  }
}
.consulting-and-optimization {
  &:before {
    content: $consulting-and-optimization; 
  }
}
.documents {
  &:before {
    content: $documents; 
  }
}
.online-delivery-service {
  &:before {
    content: $online-delivery-service; 
  }
}
.saving-money {
  &:before {
    content: $saving-money; 
  }
}
.success {
  &:before {
    content: $success; 
  }
}
.technical-project-engineering {
  &:before {
    content: $technical-project-engineering; 
  }
}

