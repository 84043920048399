.js-iframePreview {
    div[data-url] {
        display: none;
    }
}

.c-iframePreview {
    &__box {
        position: relative;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}
