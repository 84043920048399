.c-draftsList {
    position: relative;

    .input-field,
    .c-blank-box {
        margin-top: $spacer * 2;
    }

    &__show {
        text-align: center;
        margin-top: $spacer;

        .btn-flat {
            color: $primary-color;
        }
    }

    .c-draft {
        display: flex;
        align-items: center;
        padding: $spacer 0;

        &:not(:last-child) {
            border-bottom: 1px solid $lighten-color;
        }

        &__link {
            color: $black-color;
            padding-right: $spacer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            i.material-icons {
                font-size: 24px;
                vertical-align: middle;
                margin-right: 4px;

                @media #{$medium-and-up} {
                    font-size: 38px;
                }
            }

        }

        &__cta {
            margin-left: auto;
            text-align: right;
            flex-basis: 160px;
            flex-wrap: nowrap;
            flex-grow: 0;

            .input-field--checkbox {
                margin: 0;

                input[type="checkbox"] + span:not(.lever) {
                    padding-left: 0;
                }
            }
        }

        &__btn {
            $size: 24px;

            width: $size;
            height: $size;
            line-height: $size;

            i.material-icons {
                width: inherit;
                height: inherit;
                line-height: inherit;
            }
        }
    }
}
