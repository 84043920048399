.c-tags {
    margin-bottom: 1rem;

    .material-icons {
        vertical-align: middle;
    }

    > * {
        display: inline-block;

        &:not(:last-child) {
            margin-right: .75rem;
        }
    }
}
