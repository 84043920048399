.c-card {
    $c: '.c-card';

    position: relative;
    color: $black-color;
    overflow: hidden;

    .c-tags {
        .vm-icon {
            font-size: 1.8rem;
            color: $primary-color;
            vertical-align: middle;
        }
    }

    .c-productsPreview {
        margin-top: 3rem;
        margin-bottom: 1rem;

        @media (min-width: $medium-screen) {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: $ribbon-size;
        }
    }

    &__flexItems {
        @media (min-width: $medium-screen) {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding-right: $ribbon-size;

            > * {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
            }
        }
    }

    &__table {
        border-top: 1px solid rgba(160, 160, 160, 0.2);
        padding-left: $card-padding / 2;
        padding-right: $card-padding / 2;

        @media (min-width: $medium-screen) {
            padding-left: $card-padding;
            padding-right: $card-padding;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: $card-padding / 2 !important;
        padding-right: $card-padding / 2 !important;

        @media (min-width: $medium-screen) {
            padding-left: $card-padding !important;
            padding-right: $card-padding !important;
        }

        strong {
            color: $black-color;
        }
    }

    &--resume {
        .c-titleGroup__title {
            font-size: 1.6em;
        }

        #{$c}__content {
            min-height: 80px;
        }

        #{$c}__footer {
            text-align: right;
            justify-content: flex-end;
        }
    }

    &--stats {
        font-weight: 600;

        .card-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        #{$c}__value {
            font-size:  2.8rem;
        }

        #{$c}__label {
            font-size:  1.5rem;

            small {
                display: block;
                font-weight: 400;
            }
        }
    }
}
