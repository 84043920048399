.c-stats {
    text-align: center;
    color: $black-color;

    &__title {
        font-size: 1.4rem;
    }

    &__value {
        background-color: $primary-color;
        display: inline-block;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        color: $white-color;
        font-size: 40px;
        line-height: 100px;
        margin: 1rem 0;
    }

    &__subvalue {
        font-size: .9rem;
    }
}
