.c-stepper {
    $size: 24px;
    margin-bottom: $spacer;
    color: $ui-color;

    > span {
        position: relative;
        display: inline-block;
        border-radius: 100%;
        border: 2px solid $ui-color;
        width: $size;
        height: $size;
        background-color: #fff;
        text-align: center;
        vertical-align: top;
        user-select: none;

        i {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
        }

        &:not(:last-child) {
            margin-right: $size - 6px;

            &:before {
                position: absolute;
                width: $size + 4px;
                height: 1px;
                content: '';
                background-color: $ui-color;
                top: 50%;
                right: -$size - 6px;
            }
        }

        &.is-success {
            border-color: $success-color;
            color: $success-color;
        }

        &.is-warning {
            border-color: $error-color;
            color: $error-color;
        }

        &.is-danger {
            border-color: $danger-color;
            color: $danger-color;
        }

        &.is-dead {
            border-color: #000;
            background-color: #000;
            color: #000;
        }
    }
}
