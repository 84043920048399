.c-filePreview {
    display: flex;
    align-items: flex-end;
    margin-bottom: $spacer * 2;

    .input-field {
        width: 100%;
        margin-bottom: 0;

        input.file-path {
            margin-bottom: 0;
        }
    }

    &__preview {
        flex-basis: 150px;
        flex-grow: unset;
        margin-left: $gutter-width;
    }
}
