.s-error-page {
    text-align: center;
    padding: 3rem 0;

    &__code {
        font-size: 5rem;
        color: #000;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    &__message {
        font-size: 2rem;
    }

    &__cta {
        margin-top: 3rem;
    }
}
