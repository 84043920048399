.c-page {
    position: relative;
    padding-top: $navbar-height-mobile / 4;
    padding-bottom: $navbar-height-mobile / 4;
    flex: 1;
    overflow: hidden;

    @media #{$medium-and-up} {
        padding-top: $navbar-height / 2;
        padding-bottom: $navbar-height;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    @media #{$large-only} {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
