.c-loader {
    &.is-overlay {
        position: absolute;
        top: -12px;
        left: -12px;
        bottom: -12px;
        right: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($white-color, .9);
        z-index: 3;
    }

    &.is-small {
        .preloader-wrapper {
            width: 26px;
            height: 26px;
        }
    }
}
