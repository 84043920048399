.c-header {
    z-index: $header-z-index;

    nav {
        background-color: color('wecanstopit', 'white');
    }

    &__wrapper {
        padding-left: $gutter-width / 2;
        padding-right: $gutter-width/ 2;
    }

    &__toggle {
        margin-left: 0 !important;
        margin-right: $gutter-width / 2;
        display: inline-block !important;

        i:last-child {
            display: none;
        }

        &.is-toggle {
            i:first-child {
                display: none;
            }

            i:last-child {
                display: inline;
            }
        }

        @media #{$large-and-up} {
            display: none !important;
        }
    }

    &__logo {
        font-size: 2rem;

        img {
            position: relative;
            width: 160px;
            top: -5px;

            @media #{$medium-and-up} {
                width: 200px;
                top: -3px;
            }
        }
    }

    &__nav {
        float: right;
        margin-right: -$gutter-width / 2;

        a {
            color: $body-text;
        }

        i.material-icons {
            vertical-align: bottom;
            display: inline-block;
        }

        [data-target="header-dropdown-profile"] {
            text-transform: uppercase;
        }

        .dropdown-content {
            min-width: 240px;
            max-height: 280px;
            overflow: auto;

            li button {
                font-size: 16px;
                color: $dropdown-color;
                display: block;
                line-height: 22px;
                padding: (($dropdown-item-height - 22) / 2) 16px;
                display: block;
                width: 100%;
                text-align: inherit;
                background: transparent;
                border: 0;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }

                > i.material-icons {
                    height: inherit;
                    line-height: inherit;
                    float: left;
                    margin: 0 24px 0 0;
                    width: 24px;
                }
            }
        }

        .dropdown-trigger {
            position: relative;

            .badge {
                position: absolute;
                top: $badge-height / 2;
                right: -2px;
                font-size: 0.75rem;
                border-radius: 10px;
                width: 30px;
                height: 20px;
                padding: 0;
                margin: 0;
                text-align: center;
                display: block;
                min-width: initial;
                font-weight: 600;
                line-height: 1rem;
                border: 2px solid #fff;

                &:after {
                    display: none;
                }
            }
        }

        .collection {
            $c: '.collection';

            &-header {
                font-weight: 500;
            }

            &-item {
                padding: 0;
            }

            &.with-header {
                #{$c}-item {
                    padding-left: 0;
                }
            }
        }

        #header-dropdown-notification {
            width: 300px !important;

            .notification-date {
                font-size: 12px;
            }

            .collection-item {
                position: relative;

                &:hover {
                    background-color: white;
                }

                &.unread {
                    background-color: rgba($ui-color, .5);

                    a {
                        color: #000;
                    }
                }

                .remove_notification {
                    position: absolute;
                    top: 8px;
                    right: 0px;

                    a.btn-flat {
                        margin: 0px;

                        .material-icons {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .header-items {
                display: flex;
                padding: 0;
                color: $body-text;

                & > div:first-child {
                    flex-grow: 1;
                    padding-left: 12px;
                }

                a.btn-flat {
                    margin: 0px;
                }
            }
        }
    }
}
