.c-imageBox {
    border-radius: 3px;
    border: 1px solid $ui-color;
    width: 150px;
    height: 150px;
    padding: $spacer / 4;

    @media #{$medium-and-up} {
        padding: $spacer / 2;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        vertical-align: middle;
    }
}
