.c-countdown {
    line-height: 1.2;
    visibility: hidden;

    &.is-visible {
        visibility: visible;
    }

    &__title {
        text-transform: uppercase;
        color: $body-text;
        margin-bottom: $spacer / 2;
    }

    &__value {
        color: #000;
        font-size: 1.5rem;
        line-height: 1.2;

        small {
            color: $body-text;
        }
    }

    &__dd::after,
    &__hh::after,
    &__mm::after {
        font-size: 1.2rem;
    }

    &__dd::after {
        content: 'd';
        margin-right: $spacer / 2;
    }

    &__hh::after {
        content: 'h';
        margin-right: $spacer / 2;
    }

    &__mm::after {
        content: 'min';
    }
}
