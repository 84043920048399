body {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $body-bg;
    color: $body-text;
}

table img {
    height: 150px;
}

.is-relative {
    position: relative;
}

.is-absolute {
    position: absolute;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}
