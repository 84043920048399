.c-ribbon {
    position: absolute;
    top: 0;
    right: 0;
    width: $ribbon-size;
    height: $ribbon-size;
    z-index: 2;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        text-transform: uppercase;
        background-color: $primary-color;
        color: #fff;
        font-weight: 600;
        font-size: .7rem;
        line-height: 1.8;
        -webkit-font-smoothing: antialiased;
        display: block;
        height: 19px;
        text-align: center;
        width: 200%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    &.is-success span {
        background-color: $success-color;
    }

    &.is-warning span {
        background-color: $error-color;
    }

    &.is-danger span {
        background-color: $danger-color;
    }

    &.is-dead span {
        background-color: $black-color;
    }

    &.is-primary span {
        background-color: $primary-color;
    }

    &.is-ui span {
        background-color: $ui-color;
    }

    + .c-titleGroup,
    + .c-card__flexItems .c-titleGroup {
        padding-right: $ribbon-size / 2;
    }
}
