.c-resume-table {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    table {
        table-layout: fixed;
        width: 100%;
        min-width: 320px;
        margin-left: -5px;
        margin-right: -5px;

        th,
        td {
            vertical-align: top;
        }

        thead tr {
            border-bottom: 0;

            th {
                padding-bottom: 0;
            }
        }

        tbody > tr:last-child {
            border-bottom: 0;

            td {
                padding-top: 0;
            }
        }
    }
}
