.c-search {
    $c: '.c-search';

    position: relative;
    margin-bottom: 0;

    &__bar {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        #{$c}__input {
            flex-grow: 1;
            margin-right: $spacer;

            .input-field {
                margin-bottom: -7px;
            }
        }
    }

    &__filters {
        .collapsible {
            border: 0;
            box-shadow: none;

            &-header {
                color: $primary-color;
                text-align: center;
                border: 0;
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
                background-color: transparent;
                display: block;

                i {
                    vertical-align: bottom;
                    margin-right: 0;
                }

                @media #{$medium-and-up} {
                    // display: none;
                }
            }

            &-body {
                border: 0;
                padding: $spacer 0 0;

                @media #{$medium-and-up} {
                    .col {
                        min-height: 100px;
                    }
                }
            }
        }
    }
}
