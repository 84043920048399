h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    color: $headings-color;
    margin-bottom: 1.5rem;
}

strong {
    font-weight: 600;
    color: #000;
}

table {
    > thead {
        color: $headings-color;
    }

    .c-imageBox {
        width: 70px;
        height: 70px;
    }
}

dl {
    dt {
        color: #000;
        margin-bottom: $spacer / 2;
    }

    dd {
        margin-left: 0;
        margin-bottom: $spacer;
    }

    a {
        color: $primary-color;
    }
}

.lead {
    font-size: 130%;
}

.color-primary { color: $primary-color; }
.color-primary-light { color: $primary-color-light; }
.color-primary-dark { color: $primary-color-dark; }
.color-secondary { color: $secondary-color; }
.color-success { color: $success-color; }
.color-error { color: $error-color; }
.color-danger { color: $danger-color; }
.color-link { color: $link-color; }
.color-ui { color: $ui-color; }
