.c-notificationBar {
    position: relative;
    background-color: lighten($error-color, 40%);
    color: $black-color;
    font-weight: 600;
    padding: ($spacer / 2) $spacer;
    border-radius: 12px;
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 1rem;

        i {
            font-size: 2rem;
        }
    }

    &__body {
        p {
            margin: 0;
        }
    }

    &__cta {
        margin-left: auto;
    }
}
