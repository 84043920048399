.c-lockscreen {
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    background-color: rgba($white-color, .95);
    display: flex;
    text-align: center;
    z-index: 3;

    &__wrap {
        padding: $spacer * 2 $spacer;
        width: 100%;
    }

    &__icon {
        width: 80px;
        height: 80px;
        color: $white-color;
        background-color: $primary-color;
        text-align: center;
        border-radius: 100%;
        margin: 0 auto $spacer;

        i {
            font-size: 2.4rem;
            line-height: 80px;
        }
    }

    &__text {
        text-transform: uppercase;
        color: $black-color;
    }
}
